.SideMenu{
  background-color: white;
  text-align: left;
  width: 200px;
  height: auto; 
}

.ConMenu{
  margin: 20px;
  background-color: #ECECEC;
  width:auto ;
  height: auto;
  min-height:400px;
  padding: 20px;
  border-radius: 10px;
  /* border-right:1px solid #E9564B; */
  position: fixed;   
   
}

.selection{
  border-radius: 20px;
  padding-left: 20px;
  background-color: #f5c0c0e4;
  width: 90%;
  border: none;
  margin-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
}

.sideBarBurger{
  width: 40px;
  height: 40px;
  background-color: transparent;
  border:none;
  color: inherit;
  display: none;
}


@media screen and (max-width: 850px) {
  .burger,
  .burger::after,
  .burger::before{
    display: block;
    width: 40px;
    height: 3px;
    position: relative;
    border-radius: 3px;
    background: #E9564B;
    transition: all .5s ease-in-out;
  }

  .burger::after,
  .burger::before{
    content: "";
    position: absolute;
    left:0;
  }

  .burger::before{
    transform: translateY(-12px) ;   
  }

  .burger::after{
    transform: translateY(12px) ;
  }

  .ConMenu{  
    flex-direction: column;     
    position: fixed;
    justify-content: center;
    align-items: center;
    left: -100vw; 
    height: auto;
    padding: 2rem;
    visibility: hidden;
    transition: all .8s ease-out;
    padding: 0;
    padding-top: 3px;
    padding-bottom: 2px;
  }

  .showNavBarr .ConMenu{
    left: 5vw;
    top:20vh;
    width: auto;
    visibility: visible;   
   }

   .showNavBarr .burger{
    width: 0 ;
    background: transparent;
   }

   .showNavBarr .burger::before{
    transform:rotate(45deg);
   }

  .showNavBarr .burger::after{
  transform:rotate(-45deg);
  }

  .sideBarBurger{
    display:block ;
    top:0.1rem;
    right: 10rem;
    margin-left: 205px;  
  }

  .sideBarBurger:hover{
    cursor: pointer;
  }

  .SideMenu{
    margin-left: -175px;
    margin-top: 3vh;
  }

  .listGovAdh{
    transform: translateY(100vh);
  }

  .showNavBarr .listGovAdh{
    transform: translateY(0);
  }
  .showNavBarr .slideInDownnn-1{
    transition: all 1s ease-out;
  }

  .showNavBarr .slideInDownnn-2{
    transition: all 1.1s ease-out;
  }

  .showNavBarr .slideInDownnn-3{
    transition: all 1.2s ease-out;
  }

}