.container{
    display: flex;
    background-color:#ECECEC;
    margin-top: 7%;
    margin-left: 1%;
    margin-right: 1%;
    /* border-radius: 30px; */
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    /* margin-bottom: 4%; */
    height: 450px;
    width: 98%;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
}


.left{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    margin: 45px 15px 20px 70px;
}

.desc{
    /* margin-bottom: 10px; */
    width: 70%;
    justify-content: center;
    text-align: justify;
    line-height: 1.8;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    margin-top: 28px;
    
}

.SocialContainer{
    margin: 10px 5px;
    display: flex;
    text-align: center;
    margin-top: 26px;

}

.SocialIcon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    text-align: center;

}

.Center{

    text-align:left;
    flex: 1;
    padding-top: 20px;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 42px -15px 20px 15px;

}

Title{ 
   
    /* margin-bottom: 26px; */
    text-align:left;
    /* padding: 10px; */
    font-family: Arial, Helvetica, sans-serif ,bold;
    font-size: 20px;
    /* padding-top: 50px; */

 }

 .List{
    margin-top: 20px;
    /* margin: 0; */
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    text-decoration:none;
    list-style-type: upper-roman;
    /* line-height: 1.5; */
    /* display: inline; */
    /* display: list-item; */
    /* width: auto; */
    font-size: 18px;
 }

 .ListItem{
    width: 70%;
    margin-bottom: 10px;
    text-decoration:none;
    /* line-height: 1.5; */
    display: inline;
    transform: translate(0%, 50%);
    line-height: 1.8;
    text-transform: capitalize;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
 }

 
 .ListItem::before {
    content: "•"; 
    color: #E9564B;
    display: inline-block;
    width: 1em; 
    margin-left: -1.5em;
    margin-right: 0.5em;
    text-align: right; 
    direction: rtl;
    font-size: 20px;
}

.ListItem:focus-within {
    /* box-shadow: 1px 1px 3px #E9564B; */
    color:#E9564B;
}

/* .ListItem link{
    display: inline;
    text-decoration:none;
} */

.ListItem::after {
    content: ""; 
    z-index: -1;
    position: absolute;
    width: 70%; 
    height: 2px;
    background-color: red;
    transform: scale(0);
    transition: transform 0.5s ease-in-out;
    display: block;
    text-decoration: overline;
}

.ListItem:hover::after{
transform: scale(1);

}
 
.ListItem:active::after{
    transform: scale(1);
}

 .Right{
    
    flex: 1;
    padding-top: 30px;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 42px 15px 20px 10px;
    text-align: left;
 }

  /* .Right1{ */
    
    /* flex: 1; */
    /* padding-top: 50px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: -30px;
    margin-right: 99px; */
    /* padding-right: -10px; */
    /* padding-left: -50px;
    margin-top: 42px; */
 /* }  */

 .ContactItem{
    /* margin-bottom: 20px; */
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 26px;

 }



 .popup{

    height: 70% ;
    width: 70%;
    /* position: absolute; */
    top: 0%;
    margin:auto;
    margin-top: 10%;
    right: 10%;
    bottom :10%;
    padding: 2%;
    text-align: justify;
    display: inline;
    padding: 0 30px 0 15px;
    /* display: block; */
    /* position: absolute; */

}
.main22{
    position: fixed;
    top:0;
    /* margin-top:-225px; */
    transition-property: margin-top;
    transition-duration: 2s;
    bottom:0;
    right:0%;
    left: 0%;
    background-color: rgb(0, 0, 0,0.5);
    padding: 15%;
    height: 100%;

}



.ccouleur{
    background-color: white;
    padding: 40px 50px 50px 50px;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    line-height: 1.8;
    
    /* height:    970px ; */
    /* position: absolute; */



}

.popup-header{
    /* display: flex; */
    justify-content: space-between;
    padding: 0 30px 0 15px;
    /* border-bottom: 2px solid black; */
    text-align: right;
    background-color: white;
    border-top-right-radius:1.2rem ;
    border-top-left-radius:1.2rem ;
    color:gray;
    
}

@media screen and (max-width: 850px) {

        

        .container{
            flex-direction: column;
            height: auto;
            width:95%;
            margin-top: 40%;
        }

        .left{
            margin: 30px;
            /* padding: -10px; */

        }
        .Center{
            width: 100%;
            margin-left: 30px;
            margin-right: 30px;
            margin-top: auto;
        }

        .desc{
            width:100%;

        }

        .Right1{
            width: 100%;
            margin-left: 30px;
            margin-right: 30px;
            margin-top: auto;
        }
        .SocialContainer{
            
            text-align: center;
        }

        .Right{
            width: 100%;
            margin-left: 30px;
            margin-right: 30px;
            margin-top: auto;

        }

        .ContactItem{
            font-size: 15px;
        }

        .Title{
            /* margin-top: 10px;  */
            text-align: left;
        }

        .main22{
            padding: 2px;
            font-size: 16px;
            margin-top: 70px;
        }

        .ccouleur{

            background-color: white;
            padding: 30px 50px ;          
            line-height: 1.5;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: -3px;

        }

        .popup-header{
            margin-left: 20px;
            margin-right: 20px;
                /* border-bottom: 2px solid black; */

        }

        

        

}


