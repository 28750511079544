.service3 {
  position: absolute;
  margin-top: 10rem;
  align-items: center;
  width:100%;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1rem;
  margin-left: 2rem;
}

.card {
  flex-basis: calc(25% - 60px); 
  margin-left: 40px; 
  margin-bottom: 20px; 
  background-color: rgb(255, 255, 255); 
  border-radius: 30px; 
  height: 300px; 

}

#card-image-fixed {
  width: 100%; 
  height: auto;
  max-height: 100%; 
  border-top-left-radius: 30px; 
  border-top-right-radius: 30px;
  object-fit: contain; 
  margin: auto; 
}


.service-text2 {
  margin-top: -1rem;
  font-size: 1.3rem;
  color: black;
  text-align: center;
  margin-left: -11rem;
}
.service-text2 h2 {
  padding-bottom: 4rem;
}
.yooreedPartenaire h1 {
  margin-top: 1rem;
  font-size: 7rem;
  position: absolute;
  color: rgb(170, 3, 3);
  margin-left: 35%;
}
.yooreedPartenaire h3 {
  margin-top: 4rem;
  margin-left: 45%;
  font-size: 1.5rem;
  position: absolute;
  color: black;
}

.image-partenaire {
  display: flex;

  position: relative;
}
.image-partenaire img {
  display: flex;
  position: relative;
  margin-top: 25%;
  height: 350px;
  margin-left: -1px;
}
.image-partenaire2 {
  display: flex;

  position: relative;
}
.image-partenaire2 img {
  display: flex;
  position: relative;
  margin-top: 50%;
  height: 350px;
  margin-left: 780%;
}

.card-partenaire {
  margin-top: 5rem;
  width: 98%;
  margin-left: 15px;
  margin-right: 10px;
  position: relative;
  background-color: rgba(160, 148, 148, 0.982);

  height: 50rem;
  border-radius: 1rem;
}
.card-partenaire1 {
  margin-top: -4%;
margin-left: 15%;
 height: 30%;
 width: 70%;
  border-radius: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.partenaire-icons1 {
  margin-top: 2%;
  overflow: hidden;
  height: 240px;
  width: 300px;
  border-radius: 3%;
  background-color: #fff;
  display: flex;
  
  align-items: center;
}
.card-partenaire2 {
  margin-top: 16rem;
  width: 200px;

  position: absolute;
  background-color: #fff;
  margin-left: 43.2%;
  height: 200px;
  border-radius: 1rem;
}
.card-partenaire2:hover {
  transform: scale(1.1);
}
.partenaire-icons2 {
  margin-left: 15%;
  overflow: hidden;
  height: 170px;
  width: 140px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.card-partenaire3 {
  margin-top: 16rem;
  width: 200px;

  position: absolute;
  background-color: #fff;
  margin-left: 60%;
  height: 200px;
  border-radius: 1rem;
}
.card-partenaire3:hover {
  transform: scale(1.1);
}
.partenaire-icons3 {
  margin-left: 15%;
  overflow: hidden;
  height: 170px;
  width: 140px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.card-partenaire4 {
  margin-top: 30rem;
  width: 200px;

  position: absolute;
  background-color: #fff;
  margin-left: 26.5%;
  height: 200px;
  border-radius: 1rem;
}
.card-partenaire4:hover {
  transform: scale(1.1);
}
.partenaire-icons4 {
  margin-left: 15%;
  overflow: hidden;
  height: 170px;
  width: 140px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.card-partenaire5 {
  margin-top: 30rem;
  width: 200px;

  position: absolute;
  background-color: #fff;
  margin-left: 43.2%;
  height: 200px;
  border-radius: 1rem;
}
.card-partenaire5:hover {
  transform: scale(1.1);
}
.partenaire-icons5 {
  margin-left: 15%;
  overflow: hidden;
  height: 170px;
  width: 140px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.card-partenaire6 {
  margin-top: 30rem;
  width: 200px;

  position: absolute;
  background-color: #fff;
  margin-left: 60%;
  height: 200px;
  border-radius: 1rem;
}
.card-partenaire6:hover {
  transform: scale(1.1);
}
.partenaire-icons6 {
  margin-left: 15%;
  overflow: hidden;
  height: 170px;
  width: 140px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 850px) {

  .card-partenaire{
    height: 1170px;
   
  }

  .card-partenaire1 {
    margin-top: -4%;
   margin-left: 15%;
   height: 30%;
   width: 70%;
    border-radius: 2%;  
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .partenaire-icons1 {
    margin-top: 2%;
    overflow: hidden;
    height: 140px;
    width: 200px;
    border-radius: 3%;
    margin-bottom: 8px;
    display: flex;
    
    align-items: center;
  }

  .image-partenaire img {
    display: flex;
    position: relative;
    margin-top: 25%;
    height: 220px;
    margin-left: -1px;
  }

  .yooreedPartenaire h1 {
    margin-top: 1rem;
    font-size: 7rem;
    
    margin-left: 10%;
  }

  .service-text2 h2 {
    margin-left: 100px;
  }
}