.main5{
  text-align: center;
  margin-top:30px ;

}

.flex_row{
    justify-content: center;
    display: flex;
}

.proParag{
  background-color:#ECECEC;

  border-radius: 1rem;
  border: 1px solid #f0eded;
  width: 80%;
  height: 356px;
  display: flex;
  margin-top: -6.9%;
  margin-left: 10%;
  /* margin-right: 13%; */
  padding: 40px 40px 50px 50px;
  color: #707070;
  text-align: justify;
}

.proParag p{
  text-align: justify;
  line-height: 1.8;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 20px; 
  /* padding-right: 12px; */
  margin-left: auto;
  margin-right: auto;
}

.main2{
  
  margin-top: 6%;
  margin-bottom: 6%;
font-family: Arial, Helvetica, sans-serif;
/* font-size: 24px; */
}


.imag4{
  height:100%;
  object-fit:cover;
  top: 1.5%;
  /* padding-top:1.5%;  */
  margin-left: 4.9rem;
  margin-top: -7rem;
  margin-bottom: -4rem;
  position: relative;
  /* position:absolute; */
  width: 10%;
  /* margin: 0.5rem 0; */
   display:flex;
}


.imag5{
  height:100%;
  object-fit:cover;
  top: 13.5%;
  padding-top:1.5%; 
  margin-left: auto;
  margin-right: 80px;
  margin-top: -6.1rem;
  /* position: content; */
  /* position:absolute; */
  width: 10%;
  /* margin: 0.5rem 0; */
   display:flex;
   /* padding-left:-40px ; */
}

.flex_row{
  font-size: 30px;

}

.react-multi-carousel-dot--active button {
  background:#707070;
  
}

.react-multi-carousel-dot button{
  border-color: #ECECEC;
  width: 13px;
  height: 13px;
  border-width: 0p
}


.react-multiple-carousel__arrow{
  background: rgba(0,0,0,0.1);
  margin:-20px;
}

@media screen and (max-width: 850px) {
  
  .proParag{
    width: auto;
    height:auto;
    font-size: 10px;
    display:inline-table, block;
    margin-left:10px;
    margin-right: 10px;
  }

  .proParag p{
    /* width: auto;
    height:auto; */
    margin-left: -40px;
    margin-right: 87px;
  }


  .react-multiple-carousel__arrow {
    margin:-20px;
    background: rgba(0,0,0,0.1);
  }

  .main5{
    text-align: center;
    margin-top:180px ;
    width: 99%;
    margin-left:2px;
  }

  .imag4{

  margin-left: 0.9rem;
  margin-top: 75px;
  margin-bottom: -180px;
  font-size: 25px;
  /* margin-bottom:-9rem; */
}

.imag5{
  margin-left: 87%;
  margin-top:0px;
}

}
