.subb-mainn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  height: 76%;
  width: 32%;
  /* box-shadow: 11px 12px 13px 12px rgb(207, 207, 207); */
  padding-top: 80px;
  border-radius: 10px;
  margin-left: 30%;
  margin-top: 110px;

  background-color: #ececec;
}
.subb-mainn .ssss {
  margin-bottom: 50px;
  margin-top: -30px;
}
.subb-mainn .linksss {
  text-decoration: none;
  color: #222;
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 1.5rem;
  white-space: nowrap;
}

.subb-mainn .linksss:hover {
  color: #fd5353;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.subb-mainn .linksss:focus-within {
  color: #fd5353;
}
.imgs {
  padding-top: 20px;
  justify-content: center;
  display: flex;
}
.containerr-image {
  background-color: rgb(15, 15, 15);
  border-radius: 150px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 115px;
  width: 115px;
}

.contxx {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  justify-content: center;
  /* box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22); */
}

.img {
  width: 190px;
  height: 120px;
  /* justify-content: center;  */
  padding-top: 280px;

  /* padding-top: 25%; */
}

.connecttt {
  padding-bottom: 25%;
  padding-top: 100px;
  margin-top: -110px;
}
.yoorre {
  height: 50px;
  margin-top: -75px;
  margin-right: -190px;
  margin-left: 40px;
}

.nameerr {
  padding-left: 60px;
  font-size: 20px;
}
.secondd-inputt {
  padding-top: 30px;
}

.loginn-buttonn {
  padding-top: 30px;
  margin-top: 10px;
  margin-right: 230px;
}

.linkkk {
  font-size: 15px;
  font-weight: 300;
  margin-top: 30px;
}

a {
  color: #707070;
}

@media screen and (max-width: 850px) {
  .main {
    text-align: center;
    /* justify-content: center; */
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
  }
  .sub-main {
    display: flex;
    justify-content: center;
    height: 70%;
    width: 85%;
    /* box-shadow: 11px 12px 13px 12px rgb(207, 207, 207); */
    padding-top: 60px;
    border-radius: 10px;
    margin-left: 10%;
    margin-top: 30%;

    background-color: #ececec;
  }
  .sub-main .Email {
    height: -10px;
    width: 10px;
    position: absolute;
    padding: 25px 20px 14px 25px;
    color: rgb(41, 4, 4);
    background-color: #fd5353;
    /* border-radius: 50% ; */
    border-top-left-radius: 40%;
    border-bottom-left-radius: 40%;
  }
  button {
    width: 120px;
    height: 35px;
    border-radius: 50px;
    background-color: #fd5353;
    color: white;
    font-size: 15px;
    border: none;
    padding: -50px;
    margin-left: 30%;
    margin-top: 20%;
  }

  .link {
    font-size: 15px;
    font-weight: 100;
    padding-top: 50px;
  }
  a {
    color: #707070;
  }
  input {
    width: 250px;
    height: 40px;
    border-radius: 60px;
    box-shadow: inset 0px 0px 25px 0px #f8f7f7;
    border: none;
    outline: none;
    background-color: #fff;
    size: 70px;
  }

  .yoor {
    height: 50px;
    margin-top: -60px;
    margin-right: -50px;
  }
  .connect {
    padding-top: 120px;
  }
  .login-button {
    padding-top: 30px;
    margin-top: -50px;
    margin-left: 5px;
  }
}
