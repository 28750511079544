.yooreed{
    /* display: flex; */
    width: 100%;
    height: 100%;
}
.yooreed h1{
    margin-top: 7%;
    font-size: 700%;
    display: flex;
    color: rgba(240, 14, 14, 0.717);
    margin-left: 10%;
    
}
.yooreed h3{
    
    margin-top: -5%;
    margin-left: 19%;
    font-size: 150%;
    position: absolute;
    color:black;

}
.presentation{
    display: flex;
    justify-content: space-between;
   margin-top: 3%;
    align-items: center;
    margin-left: 10%;

}
.des-text{
    width: 50%;
    text-align: start;
    font-size: 1.1rem;

    
}
.des-text h2{
    padding-bottom:2rem ;
}
.image{
   
     position: relative;
     width: 40%;
    height: 40%;
 margin-left: 3%;
margin-top: 3%;
}  


.image img{
 margin-top: -170%;
    height: 200%;
    margin-left: 260%;
}
@media screen and (max-width: 850px) {
    .yooreed{
        flex-direction: column;
    }
    .image{
        width:100px !important;
        height: 100px !important;
        margin-left: -80px;

    }
    .des-text p{
       font-size: 15px;

    }
}