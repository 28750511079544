.notif{
    padding:3px;
    border-radius: 100%;
    background-color: #ECECEC;

}
.inBlock{
    display: inline;
}

.drawww{  
    background-color: #ECE0E0;
}

.notif:hover {
    background-color:#E9564B;
}

.notif:focus-within {
    background-color:#E9564B;
}

.ant-drawer-right .ant-drawer-content-wrapper{
    box-shadow:none;

}

.ant-drawer-content {    
    margin-top: 70px;
    background-color: #ECE0E0;
}

.ant-drawer .ant-drawer-content {
    background-color: #ECE0E0;
}

.ant-drawer .ant-drawer-wrapper-body{
    height: 610px;
    background-color: #ECE0E0;
}

@media screen and (max-width: 850px){
  

    .ant-badge {
        
        margin-left: -165px;
    }
}