.contenueDePage{
    flex-direction: column;
    background-color: #ECECEC;
    height: auto;
    width:800px;
    margin-top: 20px;
    border-radius: 10px;
}

.cttt{
    padding-left: 12px;
    /* flex: 6; */
    background-color: white;
    width:700px;
    height: auto;
    margin-top: 20px;
    padding-top:10px;
    margin-left: 40px;
    border-radius: 20px;
    display: flex;
    
}
.ctt{
    display: flex;    
}

.ctttt{
    width: 70%;
}
.offre{
    padding-top: 20px;
    text-align: left;
}

.description{
    padding-top: 20px;
    text-align: left;
    display:flex;
    /* width: 70%; */

}

.Title{
    padding-left: 50px;
    padding-top:20px;
}

.reduction{
    color : #E9564B;
    padding-top: 20px;
    text-align: left;
    /* display:flex; */

}

.reducNew{
    border: 1px solid #E9564B;
    border-radius: 5px;
    width: 49px;
    height: 27px;
    color: #E9564B;
    text-align: center;
    margin-left: 20px;
    padding: 5px;
    background-color: rgba(255,0,0,0.1);
}


.dateFinie{
    color : #E9564B;
    padding-top: 20px;
    text-align: left;
    display: flex;
}

.datee{
    border: 1px solid #E9564B;
    border-radius: 5px;
    width: auto;
    height: 27px;
    color: #E9564B;
    margin-left: 20px;
    padding: 5px;
    background-color: rgba(255,0,0,0.1);
}

@media screen and (max-width: 850px){

    .cttt{ 
        flex-direction: column; 
        width:85% !important; 
    }

    .ctttt{
        width:100%;
    }

    .imageOffre img{
        width: 70%;
        height: 50%;       
        margin-right: 10px;
        margin-top: 50px;
    }

    .contenueDePage{
       width:300px !important;
       margin-left: 10px;
       padding-bottom: 35%;
    }

    .description{
       display: contents !important;
       margin-top: 50px;
       font-size: 12px;

    }
    .description p{
       text-align: justify;
       font-size: 10px;
     }
    .Title{
        font-size: 12px;
    }

    .adress{
        font-size: 10px;
        display: contents !important; 
        text-align: left;   
    }

    .adress p{
        font-size: 10px;
        text-align: left;  
    }

    .tel{
        font-size: 10px;
        display: contents !important; 
        text-align: left;   
    }

    .aos-animate{
        transform:none !important;
    }

    .aos-init {
        transition-property:none !important;
    }

    .tel p{
        font-size: 10px;  
        text-align: left;  
    }

    .modal, .overlay {
        width: 100%;
        height: 100%;
        margin-top: 80px;  
    }

    button { 
        margin-right: 1%;
    }

    data-aos {
        transform: none !important;
    }

    .datee{
        font-size: 10px;
    }
    
    .dateFinie{
        font-size: 12px;
    }
    .reduction{
        font-size: 12px;
    }
}