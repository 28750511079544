.yooreedData2 h1{
    
    margin-top: 1rem;
    font-size: 7rem;
    position: absolute;
    color: rgba(240, 14, 14, 0.717);
    margin-left: 35%;
}
.yooreedData2 h3{
    margin-top: 4rem;
    margin-left: 45%;
    font-size: 1.5rem;
    position: absolute;
    color:black;
}
.service2{
   position: absolute;
   margin-top: 10rem;
    align-items: center;
    margin-left:35%;
    width: 60%;


}
.service-text{
  
    margin-top: -1rem;
    position: absolute;
    font-size: 1.3rem;
    color: black;
}
.service-text h2{
    padding-bottom:4rem ;
    
}

@media screen and (max-width: 850px) {

    .service-text h2{  
        font-size: 1.4rem;  
    }

}