.NavItems{
  background-color: #fff;
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding:0 30px;
  box-shadow: 0 3px 2px rgb(0 0 0 / 0.2);
  width: 100%;
  height: 100px;
  border-radius: 1px;
  /* position:fixed;
  z-index: 1; */

}

.navbar-logo{
 color: #222;

 font-size:2rem;
 cursor: pointer;
 margin-left: 100px;
}
.nav-menu-part{
  display: grid;
  grid-template-columns: repeat(8,auto);
  grid-gap:3px;
  list-style: none;
  align-items: center;
  margin-right: 30%;
}
.nav-links{
  text-decoration: none;
  color:#222;

  font-size:0.8rem ;
  font-weight: 600;
  padding:1rem 1.5rem;
  white-space: nowrap;
}
.nav-links i{
  width: 15%;
  margin-left: 50px;
}
.nav-links:hover{
 
  color: rgba(240, 14, 14, 0.717);
 position:block;
  transition: all 0.2s ease-in-out;
  
}
.nav-links-mobile{
  display: none;
}
button{
  padding: 0.3rem 0.3rem;
  white-space: nowrap;
  border-radius: 0.9rem;
  font-size: 1.2rem;
  font-weight: 700;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  background-color: red;
  color: #fff;
}

.menu-icons{
  display: none;
  position: inherit;
  border-radius: 100%;

  padding: 8%;
}
@media screen and (max-width: 850px) {
  .NavbarItems{
     z-index: 99;
  }
  .nav-menu{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    width: 100%;
    height: auto;
    position:absolute;
    top: 0;
    left:-100%;
    opacity: 0;
    align-items: stretch;
    padding: 80px 0 30px 0;
    margin: 0;
    transition: 0.3s ease-in-out;
  }
  .nav-menu.active{
    left: 0;
    opacity: 1;
    z-index: -1;
    transition: 0.3s ease-in-out;
    background-color: #fff;
  }
  .nav-links{
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 2rem;

  } 
  .nav-links:hover{

    transition: none;
   
  }
   button{
    display: block;
    margin-left: 20%;
    margin-right: 20%;
   
  }
   .nav-links-mobile{
     display: block;
     text-align: center;
     padding: 1.5rem;
     margin: auto;
     border-radius: 2px;
     width: 80%;
     background: #707070;
     text-decoration: none;
     color: #fff;
     font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 2px;
  }
   .menu-icons{
    display: block;
    cursor: pointer;
   }
   .menu-icons i{
    font-size: 1.2rem;
    color: #222;
   }
}
