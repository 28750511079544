.main1 {
  width: 100%;
  height: 100%;
 position: relative;
}

span img{

  width: 95%;
  height: 490px;
  margin-top: 7%;
  border-radius: 40px;   
  border-top-right-radius: 60px;
  border-top-left-radius: 60px;
  object-fit:cover;
   top: 11.5%;
  padding-top:1.5%;   

}

.contenu{

 position: absolute;
 top: 40%;
 left: 50%;
 width: 100%;
 transform: translate(-50%,-10%);
 text-shadow: 1px 8px 1px rgba(0, 0, 0, 0.2);


}


.contenu h1{
  text-align: left;
  padding-left: 70px;
  font-size: 107px;
  padding-top: 5%;
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  

}

/* .contenu h1{
 transform: translateY(50vh);
}

.contenu h1{
 transform: translateY(0);
}

.contenu h1 {
 transition: all 1.2s ease-out;
} */

.contenu h1{
 animation-duration: 3s;
 animation-name: slidein;
}

@keyframes slidein {
 from {
   margin-left: 100%;
   width: 300%;
 }

 to {
   margin-left: 0%;
   width: 100%;
 }
 75% {
    font-size: 300%;
    margin-left: 25%;
    width: 150%;
  }
}






.ovelay-img {
  position:absolute;
  margin-top: 8.6%;
  left: 2.5%;
  right: 2.5%;
  width: 95%;

  border-radius: 40px;
  display: flex;
  height: 77.6%;
  background: linear-gradient(to right, rgba(231, 69, 69, 0.6),rgba(231, 69, 69, 0.180), rgba(239, 106, 106, 0.09));
  
}
@media screen and (max-width: 555px) {
  .contenu h1{
     padding: 10px 20px;
     font-size: 51px;

  }
  
  .ovelay-img {
     position:absolute;
     margin-top: 26.5%;
     left: 0%;
     width: 100%;
     display: flex;
     height: 70%;
     background-color:rgba(239, 106, 106, 0.418);
  }
  span img{
    
     width: 100%;
     margin-top: 50%;
     /* left: 0%; */
     height: 73%;
     margin-top: 81px;

  }

  .contenu .show{

    padding: 0.5rem 2rem;
    border-radius: 50px;
    font-size: 1.1rem;
  
   
    
 }



}