.btngroupp button{
    border: 10px solid lightgray bold;
    padding: 30px 10px;
    background-color:white;
    color:black;
    display: inline;   
    cursor: pointer;
    /* font-weight: 600px; */
    
    font-size: 18px;
    width:300px;
    padding-bottom: 20px;
    padding-top: 20px;
    border: 1px solid #070707;
    margin-bottom: 20px;
    margin-top: 10px ;
    border-radius: 1rem;
    /* letter-spacing: 3px;   */
    margin-left: 100px;
    margin-right: 5px;
    text-align: center;
    /* position: relative; */
    /* padding-left: 0px; */
}
.btngroupp button:hover {
    background: linear-gradient(to right, #fd5353, #200909);
    color: white;
}
.btngroupp button:focus-within {
    background: linear-gradient(to right, #fd5353, #200909);
    color: white;
}
/* .btn-group button:click {
    background: linear-gradient(to right, #fd5353, #200909);
} */
.prag p{
 background-color:#ECECEC;
border-radius: 1rem;
border: 1px solid #ECECEC;
width: 520px;
height:auto;
margin-left: 5rem;
margin-right: 15rem;
padding:50px 50px ;
text-align: justify;
line-height: 1.8;
margin-top: -5rem;
font-family: Arial, Helvetica, sans-serif;


}

.image11m{
    height:100%;
    object-fit:cover;
    margin-right: 18.7rem;
    padding-top:0px; 
    margin-left: 7.1rem;
    margin-top: -14rem;
    /* margin-bottom: 4rem; */
    position: relative;
    /* position:absolute; */
    width: 420px;
    /* margin: 0.5rem 0; */
     display:flex;
}
.image7{
    height:50%;
    object-fit:cover;
    margin-right: 20rem;
    padding-top:50px; 
    margin-left:20rem;
    margin-top: 80rem;
    margin-bottom: 4rem; 

   position:absolute; 
    width: 20%;
     margin: 0.5rem 10rem; 
     display:flex;
}
.image6{
   
    height:50%;
    margin-right: 20rem;
    padding-top:20px; 
    margin-left:10rem;
    margin-top: 10rem;
    margin-bottom: 4rem; 

    width: 20%;
     margin: 1rem 0rem; 
   
}
.image555{
   
    object-fit:cover;
    /* margin-right: 1200px; */
    padding-top:10px; 
    margin-left: 1200px;
    margin-top: -7rem;
    margin-bottom: 10rem; 

   position:absolute; 
    width: 9%;
     /* margin: 0.5rem 10rem;  */
     display:flex;
}
.image10{
   
    margin-top: -150px;
   
    margin-left: 1280px;
     

   
    width: 5%;
     
}
.image12{
   
    margin-top: -150px;
   
    margin-right: 1280px;
     

    
    width: 5%;
     /* margin: 0.5rem 10rem;  */
    
}
.image1 {
    height: 100%;
    object-fit: cover;
    top: 11.5%;
    padding-top: 1.5%;
    margin-left: 3.1rem;
    margin-top: 3.1rem;
    margin-bottom: -4rem;
    position: relative;
    /* position: absolute; */
    width: 10%;
    /* margin: 0.5rem 0; */
    display: flex;
}
@media screen and (max-width: 850px) {
    .btngroupp {
        margin-top: 100px;
        
    }

    .btngroupp button{
        border: 10px solid lightgray bold;
        padding: 30px 10px;
        
        display: inline;   
        
        
        font-size: 18px;
        width:250px;
        padding-bottom: 40px;
        padding-top: 20px;
        border: 1px solid #070707;
        margin-bottom: 20px;
        margin-top: -10px ;
        border-radius: 1rem;
        /* letter-spacing: 3px;   */
        margin-left: 40px;
        margin-right: 50px;
        text-align: center;
        /* position: relative; */
        /* padding-left: 0px; */
        

    }

    
    
    .image11m{
        height:10%; 
        margin-right: 1.7rem;
        margin-left: -18.1rem;
        margin-top: 12rem;
        position: relative;
        width: 220px;  
        display:flex;
    }

    .prag p{

        position: relative;
        width: 300px;
        height:208px;
        margin-right: 1.7rem;
        margin-left: -21.5rem;
        margin-top: 11rem;
        padding:2px 2px ;
        text-align: left;
        line-height: 1.8;
        /* margin-top: -8rem; */

    }

    .image555{
        display:none;
    }
    .image10{
       
        display:none;
         
    }
    .image12{
       
        display:none;   
    }


}