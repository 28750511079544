.profii{
    border-radius: 100%;
    background-color: #ECECEC;
    color:black;
    margin-bottom: 3px;
    padding-top: 1px;
    cursor: pointer;
    margin-left:100px;

}

.profii:hover {
    background-color:#E9564B;
}

.profii:focus-within {
    background-color:#E9564B;
}

.drawww{
    background-color: #ECE0E0;
}


.profil:focus-within {
    background-color:#E9564B;
}

.conn{
    display:flex;
}

.ccc{
    flex:1;
}

/* .DropDownListContainer{
    transition: all .8s ease-out;

} */


.DropDownContainer{
    width: 0.5em;
    margin: 0 auto;
    margin-right:60px;
    position:relative;
}

.DropDownHeader{
    margin-bottom: 0.8em;
    padding: 0.3em 2em 0.4em 1em;
    font-weight: 500;
    font-size: 1.3rem;
    margin-left:-40px;
    margin-top:-10px;
}

.DropDownList{
    padding: 0;
    margin: 0;
    /* transition: all 2.8s ease-in-out; */

    /* padding-left: .1em; */
    background-color: #ECECEC;
    border: 2px solid #e5e5e5;
    box-sizing: border-box;
    color: black;
    font-size: 1rem;
    font-weight: 500;
    margin-left:40%;
    padding-bottom:30px;
    width:220px;
    list-style: none;
    

    &:first-child {
        padding-top: 1.2em;
    }
}

.ListItem{
    list-style: none;
    margin-bottom: 0.8em;
}

@media screen and (max-width: 850px){
  
    .DropDownHeader {
        margin-left: -80px;
    }
}
