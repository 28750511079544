.card-container {
    width: 10rem;
    border-radius: 1rem;
    box-shadow: 0px 10px 8px #999;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    background-color: white;
    height: 7rem;
    justify-content: center;
  }
  
  .card-img {
    border-radius: 1rem 1rem 0 0;
    color: rgb(234, 59, 59);
    font-size: 30px;
  }
  
  .card-title {
    margin: 0.5rem 5%;
    text-align: center;
  }
  