.NavbarItem{
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 45px;
    box-shadow: 0 3px 2px rgb(0 0 0 / 0.2);
    width: 100%;
    height: 80px;   
    border-radius: 1px;
    position: fixed;
    z-index: 1;
  }
  
  .navbar-logo{
   color: #222;
   font-size: 2rem;
   cursor: pointer;
  }

  .nav-menu-adher{
    display: grid;
    grid-template-columns: repeat(8,auto);
    grid-gap:1px;
    list-style: none;
    align-items: center;
    margin-right: 100px;

  }

  .nav-links{
    text-decoration: none;
    color:#222;
    font-size:0.8rem ;
    font-weight: 600;
    padding: 1rem 1.5rem;
    white-space: nowrap;
    
  }
  .nav-links i{
    padding-right: 10px;
    /* color:red; */
  }

  
  .nav-links:hover{
    color:#E9564B;
    /* border-radius: 4px; */
    /* transition: all 0.2s ease-in-out;   */
  }

  .nav-links:focus-within {
    /* box-shadow: 1px 1px 3px #E9564B; */
    color:#E9564B;
}
  
  .nav-links-mobile{
    display: none;
  }
  button{
    padding: 0.3rem 0.3rem;
    white-space: nowrap;
    border-radius: 0.9rem;
    font-size: 1.2rem;
    font-weight: 700;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    background-color: #f57272;
    color: #fff;
  }
  
  .menu-icons{
    display: none;
  }
  @media screen and (max-width: 850px) {
    .NavbarItems{
       z-index: 99;
    }
    .nav-menu-adher{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background: #fff;
      width: auto;
      height: auto;
      position:absolute;
      top: 0;
      left:-100%;
      opacity: 0;
      align-items: stretch;
      padding: 80px 0 30px 0;
      margin: 0;
      transition: 0.3s ease-in-out;
    }
    .nav-menu-adher.active{
      left: 0;
      opacity: 1;
      z-index: -1;
      transition: 0.3s ease-in-out;
      background-color: #fff;
    }
    .nav-links{
      display: block;
      width: 100%;
      font-size: 1.2rem;
      padding: 2rem;
      text-align: left;
  
    } 
    .nav-links:hover{
      /* background-color:red; */
      transition: none;
     
    }
     button{
      display: block;
      margin-left: 20%;
      margin-right: 20%;
     
    }
     .nav-links-mobile{
       display: block;
       text-align: center;
       padding: 1.5rem;
       margin: auto;
       border-radius: 2px;
       width: 80%;
       background: #707070;
       text-decoration: none;
       color: #fff;
       font-size: 1.2rem;
      font-weight: 700;
      letter-spacing: 2px;
    }
     .menu-icons{
      display: block;
      cursor: pointer;
      margin-left: -12px;
      margin-right: 2px;

     }
     .menu-icons i{
      font-size: 1.2rem;
      color: #222;
     }
     .logooYoor{
      /* margin-left:-140px ; */
      /* width: 30px; */

     }
  }
  