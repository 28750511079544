.contenuePage{
    flex-direction: column;
    background-color: #ECECEC;
    height: auto;
    width:67%;
    padding-bottom: 20px;
    border-radius: 10px;
    margin-left: 10px;
}

.contP{
    padding-left: 12px;
    background-color: white;
    width:97%;
    height: auto;
    margin-top: 20px;
    padding-bottom: 20px;
    padding-top:10px;
    margin-left: 20px;
    border-radius: 20px;
    display: flex;
    
}
.cont{
 
    display: flex;
    
}

.contctContainer{

    border: 5px solid #fff;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: -60px;
    margin-top: 7px;
    box-shadow: 0 2px 8px  rgb(0 0 0 / 0.2);
}

.cont img{
    width: 35px;   
    margin: 20px;  
}

.ctttt{
    width: 70%;
}
.offre{
    padding-top: 20px;
    text-align: left;
}

.description{
    padding-top: 20px;
    text-align: left;
    /* display:flex; */
    /* width: 70%; */

}

.description p{
    color: #707070;
    line-height: 1.5;
}

.Title{
    padding-left: 10px;
    padding-top:25px;
}

.reduction{
    color : #E9564B;
    padding-top: 20px;
    text-align: left;
}

.dateFinie{
    color : #E9564B;
    padding-top: 20px;
    text-align: left;
    display: flex;
}

.datee{
    border: 1px solid #E9564B;
    border-radius: 5px;
    width: auto;
    height: 27px;
    color: #E9564B;
    margin-left: 20px;
    padding: 5px;
    background-color: rgba(255,0,0,0.1);
}


@media screen and (max-width: 850px){

    .contP{ 
        flex-direction: column; 
        width:85% !important; 
    }

    .ctttt{
        width:100%;
    }

    /* .cont img{
        width: 70%;
        height: 50%;       
        margin-right: 10px;
        margin-top: 50px;
    } */

    .contenuePage{
       width:350px !important;
       margin-left: 10px;
       padding-bottom: 35%;
    }

    .description{
       display: contents !important;
       margin-top: 50px;
       font-size: 12px;

    }
    .description p{
       text-align: justify;
       font-size: 10px;
     }
    .Title{
        font-size: 12px;
    }

    .adress{
        font-size: 10px;
        display: contents !important; 
        text-align: left;   
    }

    .adress p{
        font-size: 10px;
        text-align: left;  
    }

    .tel{
        font-size: 10px;
        display: contents !important; 
        text-align: left;   
    }


    .tel p{
        font-size: 10px;  
        text-align: left;  
    }

    .modal, .overlay {
        width: 100%;
        height: 100%;
        margin-top: 80px;  
    }

    button { 
        margin-right: 1%;
    }


}
