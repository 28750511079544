.parttransaction{
    width: 100%;
    height: 100rem;
    background-color: rgb(230, 224, 224);
    position: absolute;
 
}





   
   
