.contenueDePage{
    background-color: #ECECEC;
    height: auto;
    width:800px;
    margin-top: 20px;
    margin-bottom: 30px;  
    border-radius: 10px;
}

.contenueDePage a{
    margin-left:-15px;
    margin-right: auto;
}

.imageOffre img{
    width: 200px;
    height: 70%;
    border: 1px solid #e9564ba8 ;
    border-radius:20px;
    margin-right: 10px;
    margin-top: 15%;  
}

.cttt{
    cursor:pointer;
    padding-left: 12px;   
    background-color: white;
    width:700px; 
    margin-top: 20px;
    padding-top:10px;    
    border-radius: 20px; 
    margin-left:auto;
    margin-right: auto;
    padding-bottom:20px;
    box-shadow: 0px 12px 16px -6px rgba(0,0,0,0.3);
}

.ctt{
    display: flex;  
}

.ctt img{
    border-radius:100%;
}

.ctttt{
    width: 70%;
    font-family:Arial, Helvetica, sans-serif;
}
.offre{
    padding-top: 20px;
    text-align: left;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
}

.description{
    padding-top: 20px;
    text-align: left;
    /* display:flex; */
    font-family:Arial, Helvetica, sans-serif;
    display:contents;
    font-size: 15px;
}
.descriptionModell{
    text-align: left;
    font-family:Arial, Helvetica, sans-serif;
    display:contents;
}

.descriptionModell p{
    text-align: left;
    font-size: 14px;  
}

.description p{
    color: #707070;
    line-height: 1.5;
    text-align: left;
    font-size: 14px;
    
}

.Title{
    padding-left: 50px;
    padding-top:20px;
    font-size: 18;
    font-family: Arial, Helvetica, sans-serif;
}

.reduction{
    color : #E9564B;
    padding-top: 20px;
    text-align: left;
    display:flex;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 15px;
}

.reduc{
    border: 1px solid #E9564B;
    border-radius: 10px;
    width: 49px;
    height: 27px;
    color: #E9564B;
    text-align: center;
    margin-left: 20px;
    padding: 5px;
    background-color: rgba(255,0,0,0.1);
    font-family:Arial, Helvetica, sans-serif;
}

.adress{
    display:flex;
    line-height: 1.8;
    font-size: 15px;
}

.adress p{
    color: #707070;
    font-size: 14px;   
}

.tel{
    display:flex;
    line-height: 1.8;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 15px;
}

.tel p{
    color: #707070;
    font-size: 14px;
}

body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin:20px;
    font-size: 18px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.3);
}
.modal-content {
    position: absolute;
    top: 25%;
    left: 25%;
    transform: translate(-50%, -50%);
    /* line-height: 1.4; */
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 400px;
}

.modal-content p{
    text-align: justify;
    line-height: 1.5;
    margin-top: 20px;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    width:30px;
    height: 30px;
}

.adress1{
    display: flex;
}

.tel1{
    display: flex;
}

@media screen and (max-width: 850px){

    .modal-content {
        position: absolute;
        top: 5%;
        left: 2%;
        right:5%;
        padding: 14px 28px;
        width: 20% !important;
    }

    .cttt{ 
        flex-direction: column; 
        width:85% !important; 
    }

    .ctttt{
        width:100%;
    }

    .imageOffre img{
        width: 70%;
        height: 50%;       
        margin-right: 10px;
        margin-top: 50px;
    }

    .contenueDePage{
       width:300px !important;
       margin-left: 10px;
       padding-bottom: 35%;
    }

    .description{
       display: contents !important;
       margin-top: 50px;
       font-size: 12px;

    }
    .description p{
       text-align: justify;
       font-size: 10px;
     }
     
    .Title{
        font-size: 12px;
    }

    .adress{
        font-size: 10px;
        display: contents !important; 
        text-align: left;   
    }

    .adress p{
        font-size: 10px;
        text-align: left;  
    }

    .tel{
        font-size: 10px;
        display: contents !important; 
        text-align: left;   
    }

    .aos-animate{
        transform:none !important;
    }

    .aos-init {
        transition-property:none !important;
    }

    .tel p{
        font-size: 10px;  
        text-align: left;  
    }

    .modal, .overlay {
        width: 100%;
        height: 100%;
        margin-top: 80px;  
    }

    button { 
        margin-right: 1%;
    }

    data-aos {
        transform: none !important;
    }
}
