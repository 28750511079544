.containFooter{
    background: linear-gradient(to right, #fd5353, #200909);
    height: 54px;
    color: white;
    margin-bottom: 4%; 
    margin-left: 1%;
    margin-right: 1%;
    width: 98%;
    border-bottom-left-radius:20px ;
    border-bottom-right-radius: 20px;
    text-align: center;
}

.containFooter p{
    text-align: center;
    padding-top: 20px;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 850px) {

    
    .containFooter{
        height: auto;
        width:95%;
    }

    .containFooter p{
        text-align: center;
        padding: 20px;
        font-size: 12px;
        
    }

   

    

}