.Publicites{
    margin:30px;
}
    
.juridiques{
    margin-left: 20px;
    border-radius: 20px;
    background: linear-gradient(to top, #250a0a, #fd5353);
    width:360px;
    height: 150px;
    position: fixed;
    color: white;
}

.slideTitle {
    word-break: break-word;
    font-family: "Playball";
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  
  .slideTitle .char {
    opacity: 0;
    transform: translate(0%, 50%) matrix(1, 0, 0, 1, 0, 0);
    transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
  }

.publi{
    margin-left: 20px;
    border-radius: 20px;  
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center; 
   
}

.publi video{
    width: 360px;
    height: auto;
    border-radius: 20px;
}