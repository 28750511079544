.Appp{
    display: flex;
    width: 98.5vw;
    height: 100vh;  
}

.SideMenuAndPageContent{
    margin-top:100px;
    margin-left:auto;
    margin-right:auto;   
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;

    /* background-color: rgba(0, 0, 0, 0.05) ; */
    background-color: white;
    /* background-color: #ececec; */
}