.Container{
    height: 30vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}



.Desc{
    font-weight: 300;
    margin-top:20px;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
}

.InputContainer{
    width: 80%;
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    border: 1px solid lightgray;
    margin-top:40px;
    border-radius:3rem;
}

.Input{
    border: none;
    flex: 8;
    border-radius:3rem;
    padding-left: 20px;
}

.Button{
    margin-top:15px;
    margin-left:190px;
    width: 100px;
    height: 35px;
    border: none;
    background-color: #E9564B;
    color: white;
}


.swal-button{
    display: none;
}


/* 

.swal-icon--success__line--long {
    width: 47px;
    right: 8px;
    top: 38px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: animateSuccessLong .75s;
    animation: animateSuccessLong .75s;
}

.swal-icon--success__line {
    height: 5px;
    background-color: #a5dc86;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
}

.swal-icon--success__line--tip {
    width: 25px;
    left: 14px;
    top: 46px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: animateSuccessTip .75s;
    animation: animateSuccessTip .75s;
}


.swal-icon--success:before {
    content: "";
    border-radius: 50%;
    position: absolute;
    width: 60px;
    height: 120px;
    background: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.swal-icon--success__hide-corners {
    width: 5px;
    height: 90px;
    background-color: #fff;
    padding: 1px;
    position: absolute;
    left: 28px;
    top: 8px;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.swal-icon--success:after, .swal-icon--success:before {
    content: "";
    border-radius: 50%;
    position: absolute;
    width: 60px;
    height: 120px;
    background: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.swal-icon--success__ring {
    width: 80px;
    height: 80px;
    border: 4px solid hsla(98,55%,69%,.2);
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2;
} */