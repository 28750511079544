.main{
   width: 100%;
   height: 100%;
  position: relative;
}
video{
   width: 98%;
   margin-top: 8%;
   border-radius: 20px;

  
}
.overlay-video {
   position:absolute;
   top: 12.6%;
   left: 1%;
   right: 2.5%;
   width: 98%;
   
   border-radius: 20px;
   display: flex;
   height: 87%;
   background: linear-gradient(to right, rgba(231, 69, 69, 0.6),rgba(156, 74, 74, 0.34), rgba(21, 20, 20, 0.696));

}
.content{
  position: absolute;
  height: 100%;
  left: 50%;
  width: 100%;
  transform: translate(-50%,-50%);

  
}
.content h1{
   font-size: 1000%;
   font-family: 'Arial Narrow Bold', sans-serif;
   margin-top:-15%;
   font-weight: 900%;
   background: #fff;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;

}
.content h2{
   font-size: 400%;
   margin-left: -30%;
 margin-top:1%;
 
   background: #fff;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;

}
.content h3{
   font-size: 400%;
   margin-left: 30%;
   margin-top:1%;
   background: #fff;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;

}
.content p{
   font-size: 130%;
  color: #d7d4d4d9;

 font-family: 'Arial Regular';
 
}

.content .show{
   text-decoration: none;
   
   white-space: nowrap;
  position:absolute;
  left: 45%;
   top: 120%;
   padding: 0.6rem 2rem;
   border-radius: 50%;
   font-size: 1.1rem;
   border: 3px solid #fff;
   cursor: pointer;
   letter-spacing: 1px; 
   color: #fff;
   
}

@media screen and (max-width: 555px) {
   .content h1{
      padding: 10px 20px;
      
   }
   .content p{
      font-size: 0.9rem;
      padding: 1 0 2rem 0;
   }
   .overlay-video {
      position:absolute;
      margin-top: 12%;
      left:3%;
      width: 95%;
      display: flex;
      height: 70%;
      background-color:rgba(239, 106, 106, 0.418);
   }
   video{
      width: 95%;
      margin-top: 20%;
      margin-left: 3%;
      
}
.content .show{
  
   padding: 0.5rem 2rem;
   border-radius: 50px;
   font-size: 1.1rem;
 
  
   
}


}