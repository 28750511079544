.image2{
    margin-top: 1%;
    display:flex;
    position: center;
    width: 100%;
}

.image2 img{
    margin-top: 14%;
    height: 400px;
    width: 100%;
 }

.overlay-img{
    background: linear-gradient(to right, rgba(231, 69, 69, 0.6),rgba(156, 74, 74, 0.34), rgba(21, 20, 20, 0.696)); 
    position:absolute; 
    margin-top: 14%;
    height: 400px;
    width: 100%;
    display: flex;
  
}

.card-container1{
    margin-top: 16rem;
    width: 200px;
    overflow: hidden;
   
    position:absolute;
    background-color:transparent;
    margin-left: 16%;
    height: 19rem;
    border-radius: 1rem;
    border: 2px solid #fff;
} 
 .card-container1:hover{
transform: scale(1.1);
    
}



.Service-icons1{
    margin-top: 1rem;
    margin-left: 10%;
   overflow: hidden;
   height: 120px;
   position:inherit;
   width: 160px;
   background-color: #fff;
   border-radius: 10%;
 
}
.card-container2{
    margin-top: 16rem;
    width: 200px;
    overflow: hidden;
   
    position:absolute;
    background-color:transparent;
    margin-left: 34%;
    height: 19rem;
    border-radius: 1rem;
    border: 2px solid #fff;
} 
 .card-container2:hover{
transform: scale(1.1);
    
}
.Service-icons2{
    margin-top: 1rem;
    margin-left: 10%;
   overflow: hidden;
   height: 120px;
   position:inherit;
   width: 160px;
   background-color: #fff;
   border-radius: 10%;
}

.card-container3{
    margin-top: 16rem;
    width: 200px;
    overflow: hidden;
   
    position:absolute;
    background-color:transparent;
    margin-left: 52%;
    height: 19rem;
    border-radius: 1rem;
    border: 2px solid #fff;
} 
 .card-container3:hover{
transform: scale(1.1);
    
}
.Service-icons3{
    margin-top: 1rem;
    margin-left: 10%;
   overflow: hidden;
   height: 120px;
   position:inherit;
   width: 160px;
   background-color: #fff;
   border-radius: 10%;
}

.card-container4{
    margin-top: 16rem;
    width: 200px;
    overflow: hidden;
   
    position:absolute;
    background-color:transparent;
    margin-left: 70%;
    height: 19rem;
    border-radius: 1rem;
    border: 2px solid #fff;
} 
 .card-container4:hover{
transform: scale(1.1);
    
}

.Service-icons4{
    margin-top: 1rem;
    margin-left: 10%;
   overflow: hidden;
   height: 120px;
   position:inherit;
   width: 160px;
   background-color: #fff;
   border-radius: 10%;
}
.card-text1{
    margin-top: 10rem;
    position: absolute;
    height: 5px;
    margin-left: 25%;
    color: #fff;
    font-size: 0.7rem;
}
.card-textt1{
    margin-top: 13rem;
    position: absolute;
    margin-left: 14%;
    color: #ffffffd0;
    font-size: 0.8rem;
}
.card-texttt1
{
    margin-top: 15rem;
    position: absolute;
    margin-left: 20%;
    color: #ffffff71;
    font-size: 0.7rem;
}
.card-text2{
    margin-top: 10rem;
    position: absolute;
    height: 5px;
    margin-left: 25%;
    color: #fff;
    font-size: 0.7rem;
}
.card-textt2{
    margin-top: 13rem;
    position: absolute;
    margin-left: 14%;
    color: #ffffffd0;
    font-size: 0.8rem;
}
.card-texttt2
{
    margin-top: 15rem;
    position: absolute;
    margin-left: 20%;
    color: #ffffff71;
    font-size: 0.7rem;
}
.card-text3{
    margin-top: 10rem;
    position: absolute;
    height: 5px;
    margin-left: 18%;
    color: #fff;
    font-size: 0.7rem;
}
.card-textt3{
    margin-top: 13rem;
    position: absolute;
    margin-left: 14%;
    color: #ffffffd0;
    font-size: 0.8rem;
}
.card-texttt3
{
    margin-top: 15rem;
    position: absolute;
    margin-left: 20%;
    color: #ffffff71;
    font-size: 0.7rem;
}
.card-text4{
    margin-top: 10rem;
    position: absolute;
    height: 5px;
    margin-left: 2%;
    color: #fff;
    font-size: 0.7rem;
}

.card-textt4{
    margin-top: 13rem;
    position: absolute;
    margin-left: 14%;
    color: #ffffffd0;
    font-size: 0.8rem;
}
.card-texttt4
{
    margin-top: 15rem;
    position: absolute;
    margin-left: 30%;
    color: #ffffff71;
    font-size: 0.7rem;
}

@media screen and (max-width: 850px) {
   
    .image2{
        margin-left: 0px !important;
        margin-top: 190px !important;   
    }
  
    .Service-icons1{
        margin-left: 10%;
    overflow: hidden;
    height: 100px;
    position:inherit;
    width: 100px;

    }
    .card-container1{
        margin-top: 4rem;
        width: 140px;
        margin-left: 10%;
        height: 11.2rem;
        border: 1px solid #fff;
    } 
    .card-text1{
        margin-top: 8rem;
        margin-left: 20%;  
        font-size: 0.6rem;
    }
    .card-textt1{
        margin-top: 9rem;
        margin-left: 10%;
        font-size: 0.6rem;
    }
    .card-texttt1
    {
        margin-top: 10rem;
        margin-left: 17%;
        font-size: 0.7rem;
    }

    .card-text4{
        margin-top: 7.5rem;
        margin-left: 2%;  
        font-size: 0.6rem;
    }
    .card-textt4{
        margin-top: 9rem;
        margin-left: 10%;
        font-size: 0.6rem;
    }
    .card-texttt4
    {
        margin-top: 10rem;
        margin-left: 21%;
        font-size: 0.7rem;
    }

    
    .card-text2{
        margin-top: 8rem;
        margin-left: 20%;  
        font-size: 0.6rem;
    }
    .card-textt2{
        margin-top: 9rem;
        margin-left: 10%;
        font-size: 0.6rem;
    }
    .card-texttt2
    {
        margin-top: 10rem;
        margin-left: 10%;
        font-size: 0.65rem;
    }
    .card-text3{
        margin-top: 8rem;
        margin-left: 15%;  
        font-size: 0.6rem;
    }
    .card-textt3{
        margin-top: 9rem;
        margin-left: 10%;
        font-size: 0.6rem;
    }
    .card-texttt3
    {
        margin-top: 10rem;
        margin-left: 10%;
        font-size: 0.65rem;
    }
    .card-container2{
        margin-top: 4rem;
        width: 140px;
        margin-left: 54%;
        height: 11.2rem;
        border: 1px solid #fff;
    } 
    
    .Service-icons2{
        margin-left: 10%;
        overflow: hidden;
        height: 105px;
        position:inherit;
        width: 110px;

    }
    .card-container3{
        margin-top: 16rem;
        width: 140px;
        margin-left: 10%;
        height: 11.2rem;
        border: 1px solid #fff;
    } 
    
    .Service-icons3{
        margin-left: 10%;
        overflow: hidden;
        height: 105px;
        position:inherit;
        width: 110px;
    }

    .card-container4{
        margin-top: 16rem;
        width: 140px;
        margin-left: 54%;
        height: 11.2rem;
        border: 1px solid #fff;
    } 
    
    .Service-icons4{
        margin-left: 10%;
        overflow: hidden;
        height: 100px;
        position:inherit;
        width: 110px;
    }
}



