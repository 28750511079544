.input-wrapper {
    width: 80%;
    height: 2rem;
    border: none;
    border-radius: 10px;
    padding: 0 10px;
    box-shadow: 0px 0px 5px #dddddde0;
    background-color: #ececec;
    display: flex;
    align-items: center;
    /* margin-top: -90px; */
  }

  .input-wrapper:focus-within {
    box-shadow: 1px 1px 3px #000;
}
  
  .bar_de_recherche {
    background-color: transparent;
    border: none;
    height: 70%;
    font-size: 1rem;
    width: 100%;
    margin-left: 5px;
  }
  
  input:focus {
    outline: none;
  }
  
  #search-icon {
    color: #e9564bef;
  }