.imageee img {
  background-color: #e9564b;
  width: 80px;
  height: 80px;
}

.adr {
  display: flex;
}

.adr h4 {
  color: #e9564b;
}

.teleph {
  display: flex;
}
.teleph h4 {
  color: #e9564b;
}
.gouv {
  display: flex;
}
.vill {
  display: flex;
}

.vill p {
  display: flex;
  text-align: left;
  flex-direction: row;
  width: 190px;
}
.gouv h4 {
  color: #e9564b;
  size: 20px;
  text-align: start;
}
.flex-contrainer {
  background-color: #e0e0e0;
  width: 1000px;
  margin-left: auto;
  padding-left: 5px;
  margin-right: auto;
  height: 600px;
  padding-top: 50px;
  padding-right: 80px;
}
.flex-contrainer h4 {
  color: #e9564b;
}
.reddd {
  margin-top: 20px;
  background-color: white;
  border-radius: 20px;
  height: 470px;
  width: 1100px;
  margin-top: 70px;
}

.globale {
  width: 100vw;
  height: 100vh;
  background-color: #e0e0e0;
  margin-top: 0px;
}

.part {
  display: flex;
  font-size: 17px;
  padding-top: 50px;
}

.part h4 {
  text-align: left;
  margin-left: -150px;
}

.part1 h4 {
  width: 160px;
}

.part2 h4 {
  width: 220px;
  margin-left: -80px;
}

.part p {
  color: gray;
}

.part1 {
  padding-left: 200px;
  line-height: 2;
}

.part2 {
  padding-left: 180px;
  line-height: 2;
}

.image2023 {
  width: 80px;
  height: 100px;
  margin-top: -10px;
  margin-bottom: -200px;
  margin-left: -1000px;
}

.image2024 {
  width: 80px;
  height: 100px;
  margin-top: 55px;
  margin-left: 1075px;
}
.imgg {
  width: 40px;
  height: 40px;
  margin-top: -15px;
}

.eyeAndInput {
  display: flex;
}

.frmm {
  height: 40px;
  width: 40px;
  margin-left: -2px;
}
.formcontrolProfil {
  height: 40px;
  width: 200px;
  border: 1px solid red;
  padding: 15px;
  margin-bottom: 30px;
  margin-right: 10px;
  border-radius: 20px;
  margin-left: 20px;
}

@media screen and (max-width: 850px) {
  .imageee img {
    margin-left: -800px;
  }
  .part p {
    font-size: small;
    margin-left: -40px;
  }

  .part1 {
    font-size: small;
    margin-left: -40px;
  }

  .part2 {
    font-size: small;
    margin-left: -418px !important;
    margin-top: 150px;
  }
  .Nompr {
    font-size: -50px;
    margin-left: 10px;
    padding-top: 50px;
  }

  .Nomp {
    padding-top: 10px;
    margin-left: -800px !important; 
  }

  .imgg {
    margin-left: -80px;
  }
  .reddd {
    width: 100%;
    height: auto;
  }
  .globale {
    width: 250px;
  }
  .formcontrolProfil {
    height: 5px;
    width: 80px;
    margin-left: 30px;
  }

  .frmm {
    margin-right: 30px;
    width: 30px;
    height: 30px;
    margin-left: 1px;
  }
  .bout {
    font-size: 100px;
  }
}