.details-container {
    width: 15rem;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    height: fit-content;
    padding: 10px;
  }
  
  .details-title {
    margin: 0.5rem 5%;
    text-align: center;
  }
  .details-container h2{
    border-bottom: solid rgb(233, 107, 107) 3px;
    padding: 10px;
  }
  .details-description{
    color: #999;
  }