.main5{
  text-align: center;
  margin-top:30px ;
}

.flex_row{
    justify-content: center;
    display: flex;
}

.proParag{
  background-color:#ECECEC;
    border-radius: 1rem;
    border: 1px solid #f0eded;
    width: 1000px;
    height: 356px;
    display: flex;
    margin-top: -6.9%;
    margin-left: 11%;
    /* margin-right: 13%; */
    padding: 40px 50px 50px 50px;
    color: #707070;
    text-align: justify;
}

.proParag p{
  text-align: justify;
  line-height: 1.8;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  
}

.main2{
  margin-top: 6%;
  margin-bottom: 6%;

}


.imag4{
  height:100%;
  object-fit:cover;
  top: 1.5%;
  /* padding-top:1.5%;  */
  margin-left: 4.9rem;
  margin-top: -7rem;
  margin-bottom: -4rem;
  position: relative;
  /* position:absolute; */
  width: 10%;
  /* margin: 0.5rem 0; */
   display:flex;
}


.imag5{
  height:100%;
  object-fit:cover;
  top: 10.5%;
  padding-top:1.5%; 
  margin-left: 70.3rem;
  margin-top: -9.1rem;
  position: relative;
  /* position:absolute; */
  width: 10%;
  /* margin: 0.5rem 0; */
   display:flex;
}

.flex_row{
  font-size: 30px;

}
