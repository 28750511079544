.Publicite {
    margin: 30px;
  }

 
  
  .juridique {
    border-radius: 20px;
    background: linear-gradient(to top, #250a0a, #fd5353);
    width: 290px;
    height: 150px;
    position: fixed;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .slide-title {
    word-break: break-word;
    font-family: "Playball";
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  
  .slide-title .char {
    opacity: 0;
    transform: translate(0%, 50%) matrix(1, 0, 0, 1, 0, 0);
    transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
  }
  
  .publ {
    /* border-radius: 20px; */
    /* margin-top: 100px; */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .publ video {
    width: 290px;
    height: auto;
    border-radius: 20px;
  }

  .proVideo{
    border-radius: 1rem;
    border: 1px solid #f0eded;
    width: auto;
    height: auto;
    /* margin-top: 6.9%; */
    /* margin-right: 50%; */
    /* padding: 40px 10px 50px 50px; */
  }
  
  
  @media screen and (max-width: 850px) {
    .Publicite {
      margin-left: -15px;
      margin-top: 52%;
      position: fixed;
      width: 95%;
    }
  
    .juridique {
      display: none;
    }
  
    .publ video {
      width: 75%;
      border-radius: 20px;
      margin: 0px;
    }
  }
  