.from-container {
    color:rgb(230, 227, 227);
    background: linear-gradient(to right, #fd5353ec, #200909e8);
    border-radius: 1rem;
    border: 1px solid #f0eded;
    width: 100%;
    margin-left: 8rem;
}

.from-container  .form1{
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;   
    width: 70%;
    margin: auto;  
    color:rgb(255, 235, 235);
    background-color: rgba(0, 0, 0, 0.01);
}

.from-container .input1 {
    height: 3rem;
    color:rgb(255, 235, 235);
    padding: 0 1rem;
    margin-bottom: 2rem;
    border-radius: 1.5rem;
    border: 1px solid #faf7f7 ;
    border-color: #f0eded;
    background-color: rgba(0, 0, 0, 0.01);
}

.input1:-internal-autofill-selected {
    appearance: none;
    background-image: none !important;
    background-color: rgba(0, 0, 0, 0.01) !important;
    color: rgb(252, 251, 251) !important;
}
/* .from-container input:-internal-autofill-selected {
    background-color: -internal-light-dark(rgba(0, 0, 0, 0.001), rgba(70, 90, 126, 0.04)) !important;
} */

.from-container input::placeholder {
    font-weight: bold;
    opacity: 1;
    color: hwb(0 97% 2%);
}

.from-container textarea{
    
    padding: 0.5rem 1rem;
    margin-bottom: 2rem;
    border-radius: 1.5rem ;
    border: 1px solid #ece1e1;
    background-color: rgba(0, 0, 0, 0.01);
    color:#f5f1f1;
    resize: none;
    line-height: 1.5;
    height: auto;
    font-family:sans-serif;
    overflow: hidden;
}

.from-container textarea::placeholder {
    font-weight: bold;
    opacity: 1;
    color: hwb(0 97% 2%);
   
}

.from-container button{
    display: inline;
    font-size: 1.2rem;
    padding: 12px 26px;
    margin-bottom: 2rem;
    background-color:#e8574b;
    width: 30%;
    margin-left: 170px;
    border-radius: 1.5rem;
    /* box-shadow: 0 8px 16px 0 rgba(247, 5, 5, 0.2), 0 6px 20px 0 rgba(250, 54, 5, 0.19); */
    transform: translateY(4px);
    text-align: center;
    margin-right: 200px;
}

.local{
    background-color:#ECECEC;
    border-radius: 1rem;
    border: 1px solid #f0eded;
    width: 90%;
    height:320px;
    margin-left: -1.2rem;
    margin-right: 24rem;
    padding: -30px 1px 100px 0px;
    text-align: left;
    /* margin-top: 1150px; */
    
}
td .local{
    margin-top: 45px;
}



.icon1{
    margin-left: 8rem;
    
    border-radius: 0.5rem;
    background-color:#ffffff;
    margin-right:-5.1rem;
    font-size:89.1rem;
    align-items: center;
    justify-content: center;
    width: 10px;
    display: flex;
    z-index: 1;
    /* position: fixed; */
    padding-top: -3px;
    /* justify-content: space-between;  */
    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}
.image1{
    height:100%;
    object-fit:cover;
    top: 15.5%;
    padding-top:1.5%; 
    margin-left: 3.7rem;
    margin-top: 3.1rem;
    margin-bottom: -6rem;
    position: relative;
    /* position:absolute; */
    width: 10%;
    /* margin: 0.5rem 0; */
     display:flex;
}

.image227{
    height:100%;
    /* object-fit:cover; */
    top: 1.5%;
    /* padding-top:1.5%;  */
    margin-left: 90%;
    margin-bottom:10.1rem ;
    margin-top: -17.1rem;
    position: content;
    /* position:absolute; */
    width: 10%;
    /* margin: 0.5rem 0; */
     display:flex;
}



.localisa{
    
    width: 35%;
    height: 10%;
    background-color: white;
    margin-left :109px;
    padding: 10px;
    border-radius: 0.5rem;
    color:#ffffff;
}

.localis {
    margin-top: 44px;
    width: 35%;
    height: 10%;
    background-color: white;
    margin-left :109px;
    padding: 10px;
    border-radius: 0.5rem;
    color:#ffffff;
}

td .loc{
    width: 170px;   
}

.swal-button{
    background-color: #e8574b;
}

@media screen and (max-width: 850px) {
    .from-container {
        margin: 4rem 2rem;
        width: 310px;
        height: 450px;
        /* zoom: 1; */
        flex:1;
    }

    .from-container  .form1{
        padding-top: 2rem;      
        width: 200px;
        height: 300px;
        
    }

    .from-container .input1 {
        width: 190px;
        min-height: 29px;
        background-color: rgba(0, 0, 0, 0.01);
    }

    .from-container input::placeholder {
        font-weight:lighter;
        
    }

    
.from-container textarea{
    width: 190px;
    min-height: 90px;
        
}

.from-container textarea::placeholder {
    font-weight:lighter;
    
}

.from-container button{
        /* padding-top: 0.2rem; */
       margin-left: 95px ;
        width: 40%;
        font-size: 0.8rem;
        padding: 5px 6px;
    }
    
   
    td .local{
        
        width: 60%;
        font-size: 13px;
        height: 280px;
        padding: 0%;
        padding-left: 45px;
        margin-bottom: -80px;
       /* min-height:auto; */
        margin-top: 40px;

        
    }

    td .b1b{
        display: none;
    }

    

    td .loc{

        width: 120px;
        margin-left: -90px;
        margin-right: 0px;
        margin-top: auto;
    }

    .localisa{
       
        /* margin-left: 50px;
        margin-right: 10px; */
        margin-top: auto;
        padding: auto;
        margin-right: 90px;

    }
    

    .localis{     
        margin-top: 37px;
    }

    

    .image1{
        margin-left: 8px;
    }

    .image227{
        margin-left: 18.9rem;
        margin-top: -1rem;
    }
    
    .container-position{
        /* flex: 1; */
        display:flex;
        flex-direction: column;
    }


    .swal-modal {
        width: 50%;
        margin-left: -225px;
    }


}

@media (max-width: 500px)
{
    
}
