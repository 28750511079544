.image22211{
   
    display:flex;
    position: center;
    width: 100%;
    height: 700px;
    

}
.image22211 img{
  
   height: 700px;
   
   width: 100%;
}

.choix{
    position: absolute;
    margin-top: 24rem;
     align-items: center;
     margin-left: 10%;
 
 }
 .choix-text{
     width: 50%;
     text-align: start;
     font-size: 1.1rem;
     color: #fff;
 }
 .choix-text h2{
     padding-bottom:2rem ;
     
 }

 .yooreedData h1{
     margin-top: 14%;
     font-size: 700%;
     position: absolute;
     color: black;
     margin-left: 10%;
 }
 .yooreedData h3{
     margin-top: 18%;
     margin-left: 19%;
     font-size: 1.5rem;
     position: absolute;
     color: #fff;
 }
 
 .player-wrapper{
    width:600px !important;
    height:40%;
    margin-left: 60%;
    margin-top: -50%;
    position:absolute;
 }



 .player{
  position: absolute;
  width: 30%;
  height: 30%;
 

 }
 @media screen and (max-width: 850px){
    .image22211 h3{
       margin-top: 150px;
       margin-left: 130px;
       font-size: 20px;
    }
    .image22211 h1{
        font-size: 80px;
        margin-top: 120px;
    }
    .choix-text h2{
        margin-top: -150px;
    }
    .choix-text {
       width:95%;
    }

    .image22211{
        height: 50%;
      }

      .player-wrapper video{ 
        margin-top: 190px;      
        margin-left: -760px;
        width: 250px !important;
        height: auto !important;
    }

    .image22211{
        height: 780px;
    }
 
 }