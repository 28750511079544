*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.mainSecteur{
    display: inline-block;
    width: 100%;
    height: 60vh;
}

.title{
    color: white;
    padding: 150px;
    font-size: 50px;
}
.footer{
    display: flex;
    justify-content: space-evenly;
    background-color: #707070;
    height: 50vh;
    margin: 10px;
    border-radius:  20px;
    padding: 5%;

}
.description{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(160px, 1fr));
}

.services{
    margin: 10px;
    padding: 5%;
    
}
.drop{
    display:flex;
    justify-content:center;
    align-items:center;
    color: black;
    border: solid rgb(50, 50, 50) 1px;
    margin: 10px;
    width: 25%;
  }
  
  select{
    border:none;
    padding: 10px 20px;
    border-radius:15px;
    
  }
  
  select:focus{
    outline:none;
  }
  option{
    color: black;
  }
  .topbar{
    display: flex;
    top: 0px;
    align-items: center;
    margin: auto ;
    width: 50%;
    justify-content: center;
  }
  .description_title{
    padding: 10px;
    color: rgb(250, 58, 58);
    text-align: center;
    
  }
  .details{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
  }