.Contact
{
    background-color: #fff;
    width: 100%;
    height:500px ;
}
.overlay-imgg{
    background: linear-gradient(to right, rgba(236, 0, 0, 0.658),rgba(176, 24, 24, 0.881), rgba(0, 0, 0, 0.872));
    position:absolute;
  
 
    
    margin-top: 14%;
    height: 300px;
    width: 100%;
    display: flex;
  
}
.contact-container1{
    margin-top: 17rem;
    width: 200px;
    overflow: hidden;
   
    position:absolute;
    background-color:transparent;
    margin-left: 20%;
    height: 12rem;
    border-radius: 1rem;
  
} 
 .contact-container1:hover{
transform: scale(1.1);
    
}

.contact-icons1{
    margin-top: 1rem;
    margin-left: 30%;
    
    height: 80px;
   position:inherit;
   width: 80px;
   background-color: #fff;
   border-radius: 100%;
  padding: 8%;
}
.contact-text1{
    margin-top: 7rem;
    position: absolute;
    height: 5px;
    margin-left: 10%;
    color: #fff;
    font-size: 0.7rem;
}


.contact-container2{
    margin-top: 17rem;
    width: 200px;
    overflow: hidden;
   
    position:absolute;
    background-color:transparent;
    margin-left: 35%;
    height: 12rem;
    border-radius: 1rem;
   
} 
 .contact-container2:hover{
transform: scale(1.1);
    
}


.contact-icons2{
    margin-top: 1rem;
    margin-left: 30%;
    padding: 8%;

    height: 80px;
   position:absolute;
   width: 80px;
   background-color: #fff;
   border-radius: 100%;
 
}
.contact-text2{
    margin-top: 7rem;
    position: absolute;
    height: 5px;
    margin-left: 15%;
    color: #fff;
    font-size: 0.7rem;
}


.contact-container3{
    margin-top: 17rem;
    width: 200px;
    overflow: hidden;
   
    position:absolute;
    background-color:transparent;
    margin-left: 50%;
    height: 12rem;
    border-radius: 1rem;
   
} 
 .contact-container3:hover{
transform: scale(1.1);
    
}


.contact-icons3{
    margin-top: 1rem;
    margin-left: 30%;
    
    height: 80px;
   position:absolute;
   width: 80px;
   background-color: #fff;
   border-radius: 100%;
   padding: 8%;

}
.contact-text3{
    margin-top: 8rem;
    position: absolute;
    height: 5px;
    margin-left: 6%;
    color: #fff;
    font-size: 0.7rem;
}


.contact-container4{
    margin-top: 17rem;
    width: 200px;
    overflow: hidden;
   
    position:absolute;
    background-color:transparent;
    margin-left: 65%;
    height: 12rem;
    border-radius: 1rem;

} 
 .contact-container4:hover{
transform: scale(1.1);
    
}


.contact-icons4{
    margin-top: 1rem;
    margin-left: 30%;
    padding: 8%;

    height: 80px;
   position:absolute;
   width: 80px;
   background-color: #fff;
   border-radius: 100%;
 
}
.contact-text4{
    margin-top: 7rem;
    position: absolute;
    height: 5px;
    margin-left: 25%;
    color: #fff;
    font-size: 0.7rem;
}
@media screen and (max-width: 850px) {

    .overlay-imgg{
        margin-top: 70%;
        height: 500px;
        margin-bottom: 200 ;
       
    }

    .Contact
{
    height:700px ;
}
    .contact-container1{
        margin-top: 19rem;
        width: 140px;
        margin-left: 3%;
        height: 12rem;
        border-radius: 1rem;
      
    } 



    .contact-container2{
        margin-top: 19rem;
        margin-left: 50%;
        height: 12rem;
        border-radius: 1rem;   
    } 


    .contact-container3{
        margin-top: 30.5rem;
        width: 150px;
        margin-left: 3%;
        height: 12rem;
        border-radius: 1rem;
       
    } 



    .contact-text3{
        margin-top: 7rem;
        position: absolute;
        height: 15px;
        margin-left: 15%;
        color: #fff;
        font-size: 0.65rem;
    }

    .contact-container4{
        margin-top: 30.5rem;
        margin-left: 50%;
        height: 12rem;
        border-radius: 1rem;
       
    } 




    
    
}
