.counter {
    width: 100px;
    font: 800 40px system-ui;
    overflow: hidden;
  }
  .numbers {
    width: auto;
    display: flex;
    animation: countNumber 1s infinite alternate;
    animation-timing-function: steps(10);
  }
  .numbers div {
    text-align: center;
    flex: 0 0 100px;
  }
  
  @keyframes countNumber {
    0% {
      margin-left: 0px;
    }
    100% {
      margin-left: -1000px;
    }
  }