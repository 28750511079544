*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.footer{
    display: flex;
    justify-content: space-evenly;
    background-color: #707070;
    height: 50vh;
    /* margin: 10px; */
    border-radius:  20px;
    padding: 5%;

}
.description{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(160px, 1fr));
}

.service{
    margin: 100px 10px;
    border-radius:  20px;
    background-color: #ECECEC;
    padding: 10%;
}
.three_grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 50px;
    align-items: center;
    justify-items: center;
}
.two_grid{
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 50px;
    align-items: center;
    justify-items: center;
}
.item-a{
    justify-self: end;
}

@media screen and (max-width: 850px) {
 
    .three_grid{
        grid-template-columns: repeat(2, 1fr);
        row-gap: 30px;
    }

    .two_grid{
        row-gap: 30px;
        margin-top: 30px;
    }

    .card-container {
        width: 9rem;
       
    }

    .service{
        padding: 1%;
    }
}
